'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FC } from 'react'

const queryClient = new QueryClient()

export default function withQueryClient<P>(
  WrappedComponent: FC<P> | null
): FC<P> {
  return function WithQueryClient(props: P) {
    return (
      <QueryClientProvider client={queryClient}>
        {/* @ts-ignore */}
        {WrappedComponent && <WrappedComponent {...props} />}
      </QueryClientProvider>
    )
  }
}
