import urlJoin from 'url-join'
import {
  PaymentMethod,
  PickupPoint,
  ShippingMethod,
  TAddProductToCartBody,
  TAddressForm,
  TCartState,
} from '@lib/types'
import { axiosInstance } from '@lib/utils/axios'
import { CART_ROUTE, MERGE_CART_ROUTE, PICKUP_POINTS_ROUTE } from './routes'

export const createCart = async (body: { localeCode: string }) => {
  try {
    const response = await axiosInstance.post<TCartState>(CART_ROUTE, body)
    return response.data
  } catch (error) {
    throw null
  }
}

interface GetCartByTokenParams {
  token?: string
}

export const getCartByToken = async ({ token }: GetCartByTokenParams) => {
  if (!token) {
    throw new Error('Token is not provided')
  }

  const response = await axiosInstance.get<TCartState>(
    urlJoin(CART_ROUTE, token)
  )

  return response.data
}

export const mergeCartByToken = async (cartToken: string) => {
  const response = await axiosInstance.post<TCartState>(
    urlJoin(MERGE_CART_ROUTE),
    {
      tokenValue: cartToken,
      mergeWithCurrent: true,
    }
  )

  return response.data
}

export const addProductToCart = async (
  token: string | null | undefined,
  body: TAddProductToCartBody
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const response = await axiosInstance.post<TCartState>(
      urlJoin(CART_ROUTE, tokenValue, 'items'),
      body
    )
    return response.data
  })

export const removeProductFromCart = async (
  token: string | null | undefined,
  itemId: number
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const response = await axiosInstance.patch<TCartState>(
      urlJoin(CART_ROUTE, tokenValue, 'items', String(itemId), 'remove'),
      {},
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )
    return response.data
  })

export const changeProductQuantity = async (
  token: string | null | undefined,
  itemId: number,
  quantity: number
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const response = await axiosInstance.patch<TCartState>(
      urlJoin(CART_ROUTE, tokenValue, 'items', String(itemId)),
      { quantity },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )

    return response.data
  })

export const getPickupPoints = async () => {
  try {
    const response = await axiosInstance.get<PickupPoint[]>(
      urlJoin(PICKUP_POINTS_ROUTE)
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export const setShipmentMethod = async ({
  token,
  shipmentId,
  shippingMethod,
  pickupInventorySourceId,
  pickupPointId,
}: {
  token: string | null | undefined
  shippingMethod: string
  shipmentId: string
  pickupPointId?: number
  pickupInventorySourceId?: number
}) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const response = await axiosInstance.patch<TCartState>(
      urlJoin(CART_ROUTE, tokenValue, 'shipments', shipmentId),
      {
        shippingMethod,
        ...(pickupPointId && { pickupPointId }),
        ...(pickupInventorySourceId && { pickupInventorySourceId }),
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )

    return response.data
  })

export const setDeliveryAddress = async (
  token: string | null | undefined,
  address: TAddressForm,
  billingAddress?: TAddressForm,
  email?: string,
  pickupPointId?: number
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const response = await axiosInstance.put<TCartState>(
      urlJoin(CART_ROUTE, tokenValue),
      {
        ...(email && { email }),
        billingAddress: billingAddress ?? address,
        shippingAddress: address,
        pickupPointId,
      }
    )

    return response.data
  })

export const setPaymentStepInvoice = async (
  token: string | null | undefined,
  invoiceData: TAddressForm
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const response = await axiosInstance.patch<TCartState>(
      urlJoin(CART_ROUTE, tokenValue, 'invoice'),
      invoiceData,
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )

    return response.data
  })

export const setPaymentStepMethod = async (
  token: string | null | undefined,
  paymentMethod: string,
  paymentId: string
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const response = await axiosInstance.patch<TCartState>(
      urlJoin(CART_ROUTE, tokenValue, 'payments', paymentId),
      {
        paymentMethod,
      },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )

    return response.data
  })

export const getShipmentMethods = async (
  token: string | null | undefined,
  shipmentId: number | undefined
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    if (shipmentId) {
      const { data } = await axiosInstance.get<ShippingMethod[]>(
        urlJoin(
          CART_ROUTE,
          tokenValue,
          'shipments',
          String(shipmentId),
          'methods'
        )
      )

      return data
    } else {
      throw new Error('Shipment Id was not provided.')
    }
  })

export const getPaymentMethods = async (
  token: string | null | undefined,
  paymentId: number | undefined
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    if (paymentId) {
      const { data } = await axiosInstance.get<PaymentMethod[]>(
        urlJoin(
          CART_ROUTE,
          tokenValue,
          'payments',
          String(paymentId),
          'methods'
        )
      )

      return data
    } else {
      throw new Error('PaymentId was not provided.')
    }
  })

export const completeCheckout = async (token: string | null | undefined) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const { data } = await axiosInstance.patch<TCartState>(
      urlJoin(CART_ROUTE, tokenValue, 'complete'),
      {},
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      }
    )

    return data
  })

export const redirectToPayment = async (
  token: string | null | undefined,
  paymentId?: number
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    if (paymentId) {
      const { data } = await axiosInstance.get<{
        pay_url: string
      }>(
        urlJoin(
          CART_ROUTE,
          tokenValue,
          'payments',
          String(paymentId),
          'configuration'
        )
      )

      if (data.pay_url) {
        window.location.assign(data.pay_url)
      }
    } else {
      throw new Error('PaymentId was not provided.')
    }
  })

export const applyDiscount = async (
  token: string | null | undefined,
  discountCode: string
) =>
  handleCartRequest(token, async (tokenValue: string) => {
    const { data } = await axiosInstance.put<TCartState>(
      urlJoin(CART_ROUTE, tokenValue),
      {
        couponCode: discountCode,
      }
    )

    return data
  })

export const deleteCart = async (token: string | null | undefined) =>
  handleCartRequest<boolean>(
    token,
    async (tokenValue: string) => {
      const response = await axiosInstance.delete(
        urlJoin(CART_ROUTE, tokenValue)
      )

      return response.status.toString().startsWith('2')
    },
    true
  )

const handleCartRequest = async <T>(
  token: string | null | undefined,
  callback: (tokenValue: string) => Promise<T>,
  returnFalseOnError?: boolean
): Promise<T | null> => {
  try {
    if (!token) {
      throw new Error('Cart token was not provided.')
    }

    return callback(token)
  } catch (error) {
    if (returnFalseOnError) {
      return false as unknown as T
    }

    throw error
  }
}
