export enum CartActions {
  setCartState = 'SET_CART_STATE',
  setLoadingState = 'SET_LOADING_STATE',
  setCouponError = 'SET_COUPON_ERROR',
  setCheckoutError = 'SET_CHECKOUT_ERROR',
  setLoadingQuantity = 'SET_LOADING_QUANTITY',
  setProductAddToCartState = 'SET_PRODUCT_ADD_TO_CART_STATE',
  resetAddToCartState = 'RESET_ADD_TO_CART_STATE',
}

export enum ProductContentActions {
  setLupaItems = 'SET_LUPA_ITEMS',
  setCategory = 'SET_CATEGORY',
  setLoadingState = 'SET_LOADING_STATE',
  setLupaFacets = 'SET_LUPA_FACETS',
  setTemporaryLupaFacets = 'SET_TEMPORARY_LUPA_FACETS',
  resetTemporaryLupaFacets = 'RESET_TEMPORARY_LUPA_FACETS',
  resetProductContent = 'RESET_PRODUCT_CONTENT',
}
